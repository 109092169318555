import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Service from "../../components/Services";
import GeneralTab from "../../components/HTabs";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function ClosurePvtLtdCompany() {
  /* Slider */
  // const ClosureCompSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Closure Private Limited Company`,
  //     content: `Apply for closure private limited company with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/pvt-slider.png",
  //     alt_tag: "Best Online Closure Private Limited Company Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Closure Of Pvt Ltd Company`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };

  /* About Data Without Form*/
  const ClosureCompAboutData = {
    title: `Closure Private Limited Company`,
    sub_title: `Closure private limited company in just 7 days`,
    paragraph: `How Can a Private Limited Company Be Closed? A private firm
    can be shut down in one of four ways. The business may be
    discontinued or shut down at the owners' or directors'
    discretion. The company's directors swear under oath that
    they are in charge of the attempt to liquidate the business.`,
    header: `Private limited company requests to be wound up or closed`,
    points: [`The company is unwilling to carry on with business as usual`],
  };

  /* Service Data */
  const ClosurePvtData = {
    header: `Advantages of Closure Up of Company`,
    content: [],
    image: '/imgs/registration/iec/iec-benefits.png',
    alt_img: 'TODAYFILINGS-closure-of-pvt-ltd-company',
    points: [
      `From debts and responsibility`,
      `Payments to investors or creditors`,
      `Low lost need for liquidation process`,
      `Withdrawing legal action from the company`,
      `Official liquidator`,
      `Protection for investors`,
    ],
  };
  /* Document Section Data */
  const ClosureCompDocData = {
    id: 'closure-doc',
    heading: `Closure Private Limited Company`,
    sub_heading: `Documents needed to file the appeal`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/pancard.png`,
        alt_tag: `Identification proof`,
        header: `Identification proof`,
        description: `Proof of identity like PAN Card/Aadhar Card/Voter ID card.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/passport.png`,
        alt_tag: `Residence proof`,
        header: `Evidence of residence`,
        description: `Residence proof such asPassport/Driving License/Voter ID Card.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/bankstatement.png`,
        alt_tag: `Bank Statement`,
        header: `Account statement`,
        description: `Bank account statement that has been properly certified by an
        accountant.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/form.png`,
        alt_tag: `Affidavit`,
        header: `Affidavit`,
        description: `Affidavit in Form STK-4 and Indemnity bond in Form STK-3
        duly notarised.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/certificate.png`,
        alt_tag: `Certificate`,
        header: `Certificates`,
        description: `Director's Registered Digital Signature Certificate for
        executing the form.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/application.png`,
        alt_tag: `Board resolution`,
        header: `Board resolution`,
        description: `Authenticated copy of the board resolution authorising the
        submission of this application.`,
      },
    ],
  };
  /* FaQ Data */
  var ClosurePvtFAQ = [
    {
      header: 'When should I request a list correction?',
      body: [
        {
          content: `According to (R. 34), CL may submit an application for list
          rectification following settlement.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What are the requirements for closure of private limited company?',
      body: [
        {
          content: `Document required for closing a business:`,
          icon: false,
        },
        {
          content: `Resolution from the board of directors authorizing the closing of the business.`,
          icon: true,
        },
        {
          content: `Indemnity Bond from all the Directors of the company. An affidavit from all the directors of the company.`,
          icon: true,
        },
        {
          content: `Financial Statements with NIL Liabilities.`,
          icon: true,
        },
      ],
    },
    {
      header: 'What is the closure process of Pvt Ltd company?',
      body: [
        {
          content: `First, the shareholders must pass a resolution to wind up the company. This resolution must be
           filed with the Registrar of Companies (ROC). Once the ROC approves the resolution, the company must give
            public notice of its intention to close.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the closure charges for Pvt Ltd company?',
      body: [
        {
          content: `How to Close Private Company in India? A Company closure is filed under Form STK 2 
          (Earlier form was FTE) along with the government fees of Rs. 5000/- and some necessary docs. 
          However it is important to note the cases where closure can be filed.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can one director close a company?',
      body: [
        {
          content: `A single director CANNOT close /dissolve the company. HOWEVER, IF there are misappropriations,
           malfunctions and non-compliance of the statutory laws, THEN proper proceedings have to be filed
            before the ROC, for initiating dissolving /closure of company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What happens to the money when I close my Ltd company?',
      body: [
        {
          content: `The company will stop doing business and employing people. The company will not exist once 
          it's been removed ('struck off') from the companies register at Companies House. When you liquidate a 
          company, its assets are used to pay off its debts. Any money left goes to shareholders.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'How should Statements of Affairs be attached to a petition?',
      body: [
        {
          content: `The form WIN 4 is required.`,
          icon: true,
        },
        {
          content: `Information must be more than 30 days old when a petition is filed.`,
          icon: true,
        },
        {
          content: `Verification must be made by an affidavit on form WIN 5.`,
          icon: true,
        },
      ],
    },
    {
      header: `When is a Secured Creditor eligible to vote?`,
      body: [
        {
          content: `Unless he gives up his security, a secured creditor must disclose 
          the specifics of his security.`,
          icon: true,
        },
        {
          content: `will only be able to cast a ballot about any remaining sum 
          that is owed to him after deducting the value of his security.`,
          icon: true,
        },
      ],
    },
    // 3
    {
      header: 'What steps must be taken to fill a vacancy?',
      body: [
        {
          content: `A meeting of the creditors and members must be called by CL.`,
          icon: true,
        },
        {
          content: `The tribunal has the
          authority to order that a vacancy not be filled.`,
          icon: true,
        },
        {
          content: `Despite a vacancy, the committee may continue as long 
          as it has at least two members.`,
          icon: true,
        },
      ],
    },
    // 4
    {
      header: "How is the Petition's Service rendered?",
      body: [
        {
          content: `A copy of the petition must be provided to every contributor.`,
          icon: true,
        },
        {
          content: `24 hours after the request was made.`,
          icon: true,
        },
      ],
    },
  ];

  /* Htabs */
  const ClosureCompData = {
    id: 'features',
    heading: 'Procedure For Liquidation',
    paragraph: `The process of selling the company's premises and assets in a
    summary liquidation.`,
    body: [
      {
        tab_title: 'Investors',
        tab_content: [
          {
            content_title: 'Money transfer to creditors or investors',
            content_paragraph: [],
            points: [
              `The liquidator is required to look into and examine any
            documentation provided by investors or creditors as proof
            of debt or loan. The list of creditors must also be
            provided to the Central Government by the liquidator 30
            days prior to the deadline for claims. Following approval,
            the liquidator will release the creditors from any
            outstanding debts, loans, or payments.`,
              `Within 30 days following their appointment, an official
          liquidator must call a firm's investors or creditors to a
          meeting to discuss and substantiate any claims they may
          have against the business. After receiving the call from
          the official liquidator, claims must be submitted within
          30 days in a specific manner.`,
            ],
          },
        ],
        content_img: '/imgs/business/investor.png',
        alt_img: 'TODAYFILINGS-Money transfer to investor',
      },
      {
        tab_title: 'Duties',
        tab_content: [
          {
            content_title: "Official Liquidator's Responsibilities",
            content_paragraph: [],
            points: [
              ` All of the company's property and assets must be taken
            under the official liquidator's control or custody, and
            all effects and actionable calls to which the business is
            entitled or seems to be entitled must be made by the
            liquidator. It includes all of the company's assets and
            all of its unpaid liabilities.`,
              `The official liquidator has the power to choose a
            representative or auctioneer to handle the sale of the
            company's assets and properties after gaining approval
            from the federal government.`,
            ],
          },
        ],
        content_img: '/imgs/business/duties.png',
        alt_img: 'TODAYFILINGS-Responsibilities',
      },
      {
        tab_title: 'Responsibilities',
        tab_content: [
          {
            content_title: `Obligations and tasks assigned to the official
          liquidator`,
            content_paragraph: [],
            points: [
              `All corporate affairs must be examined and investigated by the
            Official Liquidator, who must then provide a thorough
            report to the Central Government in the prescribed manner.`,
              `The report must specifically note any instances of dishonesty
            or fraud in the establishment, advertising,
            administration, or promotion of the company. In the event
            that the liquidator's report identifies any fraud or
            deceit committed by the promoters, shareholders,
            directors, or any other official of a corporation, the
            Central Government may order a more extensive
            investigation into the matters and affairs of the company.`,
            ],
          },
        ],
        content_img: '/imgs/business/responsible.png',
        alt_img: 'TODAYFILINGS-MObligation task',
      },
      {
        tab_title: 'Closure-up',
        tab_content: [
          {
            content_title: 'Order of Closure',
            content_paragraph: `After conducting a thorough investigation and the Official
          Liquidator provides a report on all verification
          processes, the Central Government may order to begin the
          Closure-up process in the same manner that a company or
          organization is wound up by the tribunal.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/closureup.png',
        alt_img: 'TODAYFILINGS-order og closure',
      },
    ],
  };
  return (
    <div>
      <Seo
        title='Closure Of Pvt Ltd Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ClosureCompSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={ClosureCompAboutData} />

          <Counter />

          <Service item={ClosurePvtData} />

          <Document docSecData={ClosureCompDocData} />

          <GeneralTab HTabsData={ClosureCompData} />
          <Cta />
          <FAQAccord items={ClosurePvtFAQ} />
        </main>
      </Layout>
    </div>
  );
}
